import * as React from "react"
import Layout from "../components/Layout";
import ContactPageContent from "../components/ContactPageContent";

const ContactPage = () => {
    return (
        <Layout pageTitle="Contact Page">
            <ContactPageContent/>
        </Layout>
    )
}

export default ContactPage;