import * as React from "react"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { MARKS } from '@contentful/rich-text-types';

const RichTextContent = ({content}) => {
    if(!content) return '';

    const options = {
        renderMark: {
        [MARKS.BOLD]: text => <b>{text}</b>,
        }
    };

    return (
        <div>
            {documentToReactComponents(JSON.parse(content.raw), options)}
        </div>
    );
}

export default RichTextContent;