import { graphql, useStaticQuery } from "gatsby";

export const useContactPageContent = () => {
    const contactPages = useStaticQuery(graphql`
        query {
            allContentfulContactPage(limit: 1) {
                edges {
                    node {
                        contactPageContent {
                            raw
                        }
                    }
                }
            }
        }`
    );

    return contactPages.allContentfulContactPage.edges[0].node;
}