import * as React from "react"
import { useContactPageContent } from "../hooks/useContactPageContent";
import RichTextContent from "./RichTextBlock";

const ContactPageHeading = () => {
    return <h1>Contact</h1>
}

const ContactPageContent = () => {
    const content = useContactPageContent();

    return (
        <div>
            <ContactPageHeading/>
            <RichTextContent content={content.contactPageContent}/>
        </div>
    );
}

export default ContactPageContent;